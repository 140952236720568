<template>
  <div class="careers">
    <div class="wrap" v-if="windowWidth">
      <div class="careersBox">
        <div class="vision">
          <span class="post">计算机视觉专家研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message">负责研发与视频内容相关的理解和制作算法</span>
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">
              结合业务需求进行数据洞察、算法调研、评测、优化、定制和创新
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >跟踪学术界和业界最新进展，推动计算机视觉算法和深度学习在众多实际应用领域的性能优化和落地
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >对于有创新和学术价值的工作，将其整理成文，并在顶级会议和期刊上与学界同行进行互动交流
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机及相关专业，博士及以上学历，5年及以上相关工作经验</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >熟练使用至少一种机器学习或深度学习的平台，有过在上述领域进行算法创新并成功落地的经历</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >熟练的英语读写能力，良好的沟通表达能力和合作共赢的团队精神</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >在CVPR、ICCV、ECCV、NeurIPS等顶会或期刊发表过论文</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="researcher">
          <span class="post">音频/语音处理资深研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >担负起相关方向的研究、孵化和技术落地，包括数据规划、模型训练、技术预研以及算法创新工作</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >和其他专业方向比如计算机视觉，自然语言处理的研究员组团攻关富有挑战性的项目</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >负责把最新的创新成果发表在相关领域的顶级刊物上，在行业和学界形成影响力
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机相关专业，在编程、数学、英语和沟通等基础能力方面有优良的教育和实践背景</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">对音频/语音相关领域充满激情并富有成果</span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >相关竞赛获奖、有相关领域顶级论文(ICASSP、Interspeech等)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="nlp">
          <span class="post">自然语言处理(NLP)资深研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >担负起相关方向的研究、孵化和技术落地，包括数据规划、模型训练、技术预研以及算法创新工作</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >和其他专业方向比如计算机视觉，自然语言处理的研究员组团攻关富有挑战性的项目</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >负责把最新的创新成果发表在相关领域的顶级刊物上，在行业和学界形成影响力
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <span class="message"
            >自然语言处理(NLP)作为智能内容理解和生成的重要组成部分，核心技术包括文章分类、自动摘要、知识图谱、情感分析、自动问答等在PCG都有广阔的应用前景</span
          >
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机相关专业，在编程、数学、英语和沟通等基础能力方面有优良的教育和实践背景，博士以上学历</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">对上述NLP相关领域充满激情并富有成果</span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >有相关竞赛获奖、有相关领域顶级论文(ACL、EMNLP、NIPS等)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="reconstruction">
          <span class="post">计算机视觉三维重建专家研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >担负起从图片或视频中显式或隐式抽取相机、光源、人脸、人体以及通用物体的三维和材质信息，并按需把这些信息作为独立输出或用于帮助其他算法比如GAN生成的进一步实现和优化</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >负责项目研究、孵化和技术落地中的数据规划、模型训练、技术预研以及算法创新工作
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >和NLP、audio、speech以及其他视觉方向的研究员合作，组团攻关富有挑战性的项目，共同打造智能内容理解和生成的硬核技术
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >负责把最新的创新成果发表在相关领域的顶级刊物上，在行业和学界形成影响力
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机相关专业，在编程、数学、英语和沟通等基础能力方面有优良的教育和实践背景，博士以上学历</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >精通经典SFM等三维重建、基于模型的三维重建、多视图立体视觉技术以及基于深度学习的三维重建</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >有高精度动态人体，人脸三维重建经验的优先</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >有计算机图形学基础，动态物体模型建模和渲染经验的优先</span
            >
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message">对上述相关领域充满激情并富有成果</span>
          </p>
          <p class="careers_text">
            <span class="number">6.</span
            ><span class="message"
              >有相关竞赛获奖，有相关领域顶级论文(CVPR、ICCV、SIGGRAPH等)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="development">
          <span class="post">AI后台开发工程师</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >负责计算机视觉算法方案的后台系统设计和开发部署，技术突破和性能优化</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >负责与算法研发和上下游业务方同事沟通，推动算法业务在可自动运维性、可用性和性能上持续改善和优化
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >负责计算机视觉服务层标准、规范和接口定义，对接不同的业务产品应用，细化技术方案，推进计算机视觉技术能力的落地</span
            >
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message">计算机专业本科及以上学历</span>
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >良好的编程基础，至少熟练如下编程语言：Python、C++，具有Linux环境下开发经验</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >具备机器学习、深度学习相关算法服务设计和开发经验，基于http、rpc的服务通信开发经验；具备常用数据库mysql、redis、mongodb等开发和使用经验；熟悉分布式系统设计，缓存、消息队列等机制；
              了解常用深度学习框架，如
              pytorch、tensorflow等，了解常用深度神经网络模型运行原理；有计算机视觉算法落地开发与后台部署经验者优先；有opencv开发、CUDA研发、模型推断加速经验优先</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message">良好的沟通、组织协调能力和强烈的责任心</span>
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message">对新技术感兴趣，有技术热情</span>
          </p>
        </div>
      </div>
      <div class="contact">
        <span class="contact_us">欢迎扫码联系或推荐</span>
        <div class="code_box">
          <p class="qr_code">
            <img src="../../../assets/img/contact.png" alt="" />
          </p>
          <p class="qr_code">
            <img src="../../../assets/img/Subscription.png" alt="" />
          </p>
        </div>
      </div>
    </div>
    <div class="wrap_mini" v-if="windowWidth == false">
      <div class="careersBox">
        <div class="vision">
          <span class="post">计算机视觉专家研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message">负责研发与视频内容相关的理解和制作算法</span>
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">
              结合业务需求进行数据洞察、算法调研、评测、优化、定制和创新
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >跟踪学术界和业界最新进展，推动计算机视觉算法和深度学习在众多实际应用领域的性能优化和落地
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >对于有创新和学术价值的工作，将其整理成文，并在顶级会议和期刊上与学界同行进行互动交流
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机及相关专业，博士及以上学历，5年及以上相关工作经验</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >熟练使用至少一种机器学习或深度学习的平台，有过在上述领域进行算法创新并成功落地的经历</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >熟练的英语读写能力，良好的沟通表达能力和合作共赢的团队精神</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >在CVPR、ICCV、ECCV、NeurIPS等顶会或期刊发表过论文</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="researcher">
          <span class="post">音频/语音处理资深研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >担负起相关方向的研究、孵化和技术落地，包括数据规划、模型训练、技术预研以及算法创新工作</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >和其他专业方向比如计算机视觉，自然语言处理的研究员组团攻关富有挑战性的项目</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >负责把最新的创新成果发表在相关领域的顶级刊物上，在行业和学界形成影响力
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机相关专业，在编程、数学、英语和沟通等基础能力方面有优良的教育和实践背景</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">对音频/语音相关领域充满激情并富有成果</span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >相关竞赛获奖、有相关领域顶级论文(ICASSP、Interspeech等)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="nlp">
          <span class="post">自然语言处理(NLP)资深研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >担负起相关方向的研究、孵化和技术落地，包括数据规划、模型训练、技术预研以及算法创新工作</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >和其他专业方向比如计算机视觉，自然语言处理的研究员组团攻关富有挑战性的项目</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >负责把最新的创新成果发表在相关领域的顶级刊物上，在行业和学界形成影响力
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <span class="message"
            >自然语言处理(NLP)作为智能内容理解和生成的重要组成部分，核心技术包括文章分类、自动摘要、知识图谱、情感分析、自动问答等在PCG都有广阔的应用前景</span
          >
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机相关专业，在编程、数学、英语和沟通等基础能力方面有优良的教育和实践背景，博士以上学历</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">对上述NLP相关领域充满激情并富有成果</span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >有相关竞赛获奖、有相关领域顶级论文(ACL、EMNLP、NIPS等)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="reconstruction">
          <span class="post">计算机视觉三维重建专家研究员</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >担负起从图片或视频中显式或隐式抽取相机、光源、人脸、人体以及通用物体的三维和材质信息，并按需把这些信息作为独立输出或用于帮助其他算法比如GAN生成的进一步实现和优化</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >负责项目研究、孵化和技术落地中的数据规划、模型训练、技术预研以及算法创新工作
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >和NLP、audio、speech以及其他视觉方向的研究员合作，组团攻关富有挑战性的项目，共同打造智能内容理解和生成的硬核技术
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >负责把最新的创新成果发表在相关领域的顶级刊物上，在行业和学界形成影响力
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >计算机相关专业，在编程、数学、英语和沟通等基础能力方面有优良的教育和实践背景，博士以上学历</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >精通经典SFM等三维重建、基于模型的三维重建、多视图立体视觉技术以及基于深度学习的三维重建</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >有高精度动态人体，人脸三维重建经验的优先</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >有计算机图形学基础，动态物体模型建模和渲染经验的优先</span
            >
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message">对上述相关领域充满激情并富有成果</span>
          </p>
          <p class="careers_text">
            <span class="number">6.</span
            ><span class="message"
              >有相关竞赛获奖，有相关领域顶级论文(CVPR、ICCV、SIGGRAPH等)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="development">
          <span class="post">AI后台开发工程师</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >负责计算机视觉算法方案的后台系统设计和开发部署，技术突破和性能优化</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >负责与算法研发和上下游业务方同事沟通，推动算法业务在可自动运维性、可用性和性能上持续改善和优化
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >负责计算机视觉服务层标准、规范和接口定义，对接不同的业务产品应用，细化技术方案，推进计算机视觉技术能力的落地</span
            >
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message">计算机专业本科及以上学历</span>
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >良好的编程基础，至少熟练如下编程语言：Python、C++，具有Linux环境下开发经验</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >具备机器学习、深度学习相关算法服务设计和开发经验，基于http、rpc的服务通信开发经验；具备常用数据库mysql、redis、mongodb等开发和使用经验；熟悉分布式系统设计，缓存、消息队列等机制；
              了解常用深度学习框架，如
              pytorch、tensorflow等，了解常用深度神经网络模型运行原理；有计算机视觉算法落地开发与后台部署经验者优先；有opencv开发、CUDA研发、模型推断加速经验优先</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message">良好的沟通、组织协调能力和强烈的责任心</span>
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message">对新技术感兴趣，有技术热情</span>
          </p>
        </div>
      </div>
      <div class="contact">
        <span class="contact_us">欢迎扫码联系或推荐</span>
        <span class="qr_code">
          <img src="../../../assets/img/contact.png" alt="" />
          <img src="../../../assets/img/Subscription.png" alt="" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
    bus.$emit("textBybus", this.text);
  },
  data() {
    return {
      bannerHeight: 400,
      text: "招聘信息",
      windowWidth: true,
      jobResponsibilities:"岗位职责",
      jobRequirements:"岗位要求"
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.careers {
  width: 100%;
  font-size: 0.16rem;
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    span {
      display: block;
    }
    .careersBox {
      margin-top: 0.6rem;
      margin-left: 0.96rem;
      margin-right: 0.96rem;
      .development,
      .researcher,
      .nlp,
      .reconstruction,
      .vision {
        .post {
          height: 0.4rem;
          line-height: 0.4rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.28rem;
          color: #222222;
        }
        .post_type,
        .job_requirements {
          margin: 0.5rem 0 0.1rem;
          height: 0.28rem;
          line-height: 0.28rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.2rem;
          color: #222222;
        }
        .careers_text {
          position: relative;
          padding-left: 0.2rem;
        }
        .message {
          line-height: 0.32rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
        }
        .number {
          display: inline-block;
          line-height: 0.32rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      border-radius: 0.06rem;
      margin: 0.56rem 0 0.5rem;
    }
    .contact {
      margin-top: 0.6rem;
      .contact_us {
        height: 0.22rem;
        line-height: 0.22rem;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.16rem;
        color: #666666;
        text-align: center;
      }
      .code_box {
        text-align: center;
        .qr_code {
          display: inline-block;
          margin: 0.3rem 0.2rem 0.6rem;
          width: 2.2rem;
          height: 2.2rem;
          text-align: center;
          border: 1px solid #e5e5e5;
          border-radius: 1px;
          img {
            height: 1.84rem;
            width: 1.84rem;
            margin: 0.18rem;
          }
        }
      }
    }
  }
  .wrap_mini {
    width: 7.5rem;
    margin: 0 auto;
    span {
      display: block;
    }
    .careersBox {
      padding: 1rem 0.8rem 0;
      .vision,
      .development,
      .researcher,
      .nlp,
      .reconstruction {
        margin-bottom: 1rem;
        .post {
          line-height: 0.67rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.48rem;
          color: #222222;
        }
        .post_type,
        .job_requirements {
          margin: 0.6rem 0 0.2rem;
          line-height: 0.5rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222222;
        }
        .careers_text {
          position: relative;
          padding-left: 0.35rem;
        }
        .message {
          line-height: 0.52rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          color: #666666;
        }
        .number {
          display: inline-block;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          line-height: 0.52rem;
          color: #666666;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      margin-bottom: 1rem;
    }
    .contact {
      .contact_us {
        height: 0.52rem;
        line-height: 0.52rem;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.28rem;
        color: #666666;
        text-align: center;
      }
      .qr_code {
        margin: 0.6rem auto 1rem;
        width: 4.4rem;
        text-align: center;
        border: 0.02rem solid #e5e5e5;
        border-radius: 0.02rem;
        img {
          height: 3.68rem;
          width: 3.68rem;
          margin: 0.36rem;
        }
      }
    }
  }
}
</style>